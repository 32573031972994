import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import BookmarksIcon from '@mui/icons-material/Bookmarks';

import GenericDialog from '../GenericDialog';

import {
    addBookmarkRequest,
    removeBookmarkRequest,
} from '../../reducers/bookmarksSlice';

function CustomInfoWindow({ place, bookmarkId }) {
    const dispatch = useDispatch();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <Card sx={{ maxWidth: 300 }}>
            <CardHeader
                sx={{ padding: 0 }}
                title={
                    <Typography variant="subtitle1">{place.name}</Typography>
                }
                subheader={
                    <Typography variant="subtitle2">
                        {place.formatted_address}
                    </Typography>
                }
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    Google Maps rating: {place.rating}⭐ (
                    {place.user_ratings_total} ratings)
                </Typography>
                <Link href={place.url}>Open in Google Maps</Link>
            </CardContent>
            <CardActions>
                {!bookmarkId ? (
                    <Button
                        variant="contained"
                        startIcon={<BookmarksIcon />}
                        onClick={async () => {
                            dispatch(addBookmarkRequest(place));
                        }}
                    >
                        Save to Bookmarks
                    </Button>
                ) : (
                    <>
                        <Button
                            variant="contained"
                            startIcon={<BookmarksIcon />}
                            onClick={async () => setIsDialogOpen(true)}
                        >
                            Delete bookmark
                        </Button>
                        <GenericDialog
                            open={isDialogOpen}
                            title="Are you sure you want to remove this bookmark?"
                            description="This action cannot be undone"
                            onAgree={() => {
                                setIsDialogOpen(false);
                                dispatch(removeBookmarkRequest(bookmarkId));
                            }}
                            onClose={() => setIsDialogOpen(false)}
                        />
                    </>
                )}
            </CardActions>
        </Card>
    );
}

export default CustomInfoWindow;

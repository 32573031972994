import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';
import middleware from './middlewares';

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(middleware),
});

export default store;

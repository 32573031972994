import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import bookmarksApi from '../api/bookmarksApi';

export const addBookmarkRequest = createAsyncThunk(
    'bookmarks/addBookmarkRequest',
    async (bookmark, _thunkAPI) => {
        try {
            const response = await bookmarksApi.postBookmark(bookmark);
            return await response.json();
        } catch (err) {
            console.error(err);
            throw err;
        }
    }
);

export const getAllBookmarksRequest = createAsyncThunk(
    'bookmarks/getAllBookmarksRequest',
    async (_, _thunkAPI) => {
        try {
            const response = await bookmarksApi.getBookmarks();
            return response;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }
);

export const removeBookmarkRequest = createAsyncThunk(
    'bookmarks/removeBookmarkRequest',
    async (bookmarkId, _thunkAPI) => {
        try {
            await bookmarksApi.deleteBookmark(bookmarkId);
            return true;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }
);

export const removeBookmarksRequest = createAsyncThunk(
    'bookmarks/bulkRemoveBookmarkRequest',
    async (bookmarkIds, _thunkAPI) => {
        try {
            await bookmarksApi.deleteBookmarks(bookmarkIds);
            return true;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }
);

const bookmarksAdapter = createEntityAdapter();

const bookmarksSlice = createSlice({
    name: 'bookmarks',
    initialState: {
        ...bookmarksAdapter.getInitialState([]),
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addBookmarkRequest.fulfilled, (state, action) => {
            bookmarksAdapter.addOne(state, action.payload);
        });
        builder.addCase(getAllBookmarksRequest.fulfilled, (state, action) => {
            bookmarksAdapter.setAll(state, action.payload);
        });
        builder.addCase(removeBookmarkRequest.fulfilled, (state, action) => {
            bookmarksAdapter.removeOne(state, action.payload);
        });
    },
});

export const { selectById: selectBookmarkById, selectAll: selectAllBookmarks } =
    bookmarksAdapter.getSelectors((globalState) => globalState.bookmarks);

// export const {} = bookmarksSlice.actions;
export default bookmarksSlice.reducer;

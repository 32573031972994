import { createSlice } from '@reduxjs/toolkit';
import {
    addBookmarkRequest,
    getAllBookmarksRequest,
    removeBookmarkRequest,
    removeBookmarksRequest,
} from '../reducers/bookmarksSlice';

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        queue: [],
    },
    reducers: {
        enqueueNotification: (state, action) => {
            state.queue.push(action.payload);
        },
        dequeueNotification: (state) => {
            state.queue.shift();
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addBookmarkRequest.fulfilled, (state) => {
            state.queue.push({
                message: 'Bookmark added successfully!',
                type: 'success',
            });
        });
        builder.addCase(addBookmarkRequest.rejected, (state) => {
            state.queue.push({
                message: 'Error adding bookmark',
                type: 'error',
            });
        });
        builder.addCase(getAllBookmarksRequest.rejected, (state) => {
            state.queue.push({
                message: 'Error fetching bookmarks',
                type: 'error',
            });
        });
        builder.addCase(removeBookmarkRequest.fulfilled, (state) => {
            state.queue.push({
                message: 'Bookmark removed successfully!',
                type: 'success',
            });
        });
        builder.addCase(removeBookmarkRequest.rejected, (state) => {
            state.queue.push({
                message: 'Error removing bookmark',
                type: 'warning',
            });
        });
        builder.addCase(removeBookmarksRequest.fulfilled, (state) => {
            state.queue.push({
                message: 'Bookmarks removed successfully!',
                type: 'success',
            });
        });
        builder.addCase(removeBookmarksRequest.rejected, (state) => {
            state.queue.push({
                message: 'Error removing bookmarks',
                type: 'warning',
            });
        });
    },
});

export const { enqueueNotification, dequeueNotification } =
    notificationsSlice.actions;
export default notificationsSlice.reducer;

import React, { useEffect, useRef, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import ReactDOMClient from 'react-dom/client';

import './Map.css';

import CustomInfoWindow from './CustomInfoWindow';

import store from '../../store';
import useGoogleMapsApi from './useGoogleMapsApi';

import {
    getAllBookmarksRequest,
    selectAllBookmarks,
} from '../../reducers/bookmarksSlice';

const DEFAULT_MAP_POSITION = { lat: 43.7181228, lng: -79.5428675 };

function Map() {
    const dispatch = useDispatch();
    const googleMapsApi = useGoogleMapsApi();

    const [currentMap, setCurrentMap] = useState(null);
    const [geolocation, setGeolocation] = useState(null);
    const [isGeolocalizing, setIsGeolocalizing] = useState(true);

    const bookmarks = useSelector(selectAllBookmarks);

    let currentMarkerData = useRef(null);
    let currentInfoWindow = useRef(null);
    let [bookmarkMarkers, setBookmarkMarkers] = useState({});
    let currentListener = null;

    const isFoodiePlace = (placeTypes) => {
        const foodiePlaceTypes = [];
        placeTypes.forEach((placeType) => {
            if (['restaurant', 'food', 'bar', 'cafe'].includes(placeType)) {
                foodiePlaceTypes.push(placeType);
            }
        });
        return foodiePlaceTypes.length > 0;
    };

    const initBookmarkMarkers = async () => {
        const updatedBookmarkMarkers = {};
        bookmarks.forEach((bookmark) => {
            if (bookmarkMarkers[bookmark.id]) {
                updatedBookmarkMarkers[bookmark.id] =
                    bookmarkMarkers[bookmark.id];
                return;
            }

            const pinBackground = new googleMapsApi.PinElement({
                background: '#FFA500',
            });

            const googleMapMarker = new googleMapsApi.AdvancedMarkerElement({
                position: { lat: bookmark.lat, lng: bookmark.lng },
                map: currentMap,
                content: pinBackground.element,
            });

            updatedBookmarkMarkers[bookmark.id] = googleMapMarker;

            googleMapMarker.addListener('click', () => {
                clearInfoWindowAndMarker();

                const request = {
                    placeId: bookmark.placeId,
                    fields: [
                        'name',
                        'formatted_address',
                        'place_id',
                        'geometry',
                        'business_status',
                        'type',
                        'url',
                        'price_level',
                        'rating',
                        'user_ratings_total',
                    ],
                };
                const service = new google.maps.places.PlacesService(
                    currentMap
                );
                service.getDetails(request, async (place, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        currentMarkerData.current = {
                            place,
                            bookmarkId: bookmark.id,
                            googleMapMarker,
                        };
                        displayInfoWindow();
                    }
                });
            });
        });

        setBookmarkMarkers(updatedBookmarkMarkers);
    };

    const initMap = async () => {
        const newMap = new googleMapsApi.Map(
            document.getElementsByClassName('Map')[0],
            {
                zoom: 15,
                center: DEFAULT_MAP_POSITION,
                mapId: 'f9ace3c14bb3ed75',
                mapTypeControl: false,
                streetViewControl: false,
            }
        );

        setCurrentMap(newMap);
    };

    const geolocalizeUser = () => {
        if ('geolocation' in navigator) {
            // Prompt user for permission to access their location
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setGeolocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error('Error getting user location:', error);
                    setIsGeolocalizing(false);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
            setIsGeolocalizing(false);
        }
    };

    const displayInfoWindow = () => {
        const { place, bookmarkId, googleMapMarker } =
            currentMarkerData.current;

        const customInfoWindowDiv = document.createElement('div');
        ReactDOMClient.createRoot(customInfoWindowDiv).render(
            <Provider store={store}>
                <CustomInfoWindow bookmarkId={bookmarkId} place={place} />
            </Provider>
        );
        currentInfoWindow.current = new googleMapsApi.InfoWindow({
            content: customInfoWindowDiv,
        });

        currentInfoWindow.current.open({
            anchor: googleMapMarker,
            map: currentMap,
        });

        currentInfoWindow.current.addListener('closeclick', () => {
            clearInfoWindowAndMarker();
        });
    };

    const displayPlace = async (placeId) => {
        const request = {
            placeId,
            fields: [
                'name',
                'formatted_address',
                'place_id',
                'geometry',
                'business_status',
                'type',
                'url',
                'price_level',
                'rating',
                'user_ratings_total',
            ],
        };
        const service = new google.maps.places.PlacesService(currentMap);
        service.getDetails(request, async (place, status) => {
            // Default Google Maps API behavior if place doesn't respect criteria
            if (
                status === google.maps.places.PlacesServiceStatus.OK &&
                place?.geometry?.location &&
                isFoodiePlace(place.types)
            ) {
                const googleMapMarker = new googleMapsApi.AdvancedMarkerElement(
                    {
                        position: place.geometry.location,
                        map: currentMap,
                    }
                );

                currentMarkerData.current = {
                    place,
                    bookmarkId: undefined,
                    googleMapMarker,
                };
                displayInfoWindow();
            }
        });
    };

    const clearInfoWindowAndMarker = () => {
        if (currentInfoWindow.current) {
            currentInfoWindow.current.close();
            currentInfoWindow.current = null;
        }
        if (currentMarkerData.current) {
            if (bookmarks.length < Object.keys(bookmarkMarkers).length) {
                currentMarkerData.current.googleMapMarker.map = null;
                const {
                    [currentMarkerData.current.bookmarkId]: _,
                    ...updatedBookmarkMarkers
                } = bookmarkMarkers;
                setBookmarkMarkers(updatedBookmarkMarkers);
            } else if (currentMarkerData.current.bookmarkId === undefined) {
                currentMarkerData.current.googleMapMarker.map = null;
            }
            currentMarkerData.current = null;
        }
    };

    useEffect(() => {
        if (googleMapsApi) {
            geolocalizeUser();
            initMap();
        }
    }, [googleMapsApi]);

    useEffect(() => {
        if (currentInfoWindow.current) {
            clearInfoWindowAndMarker();
        }
        if (googleMapsApi && bookmarks.length > 0) {
            initBookmarkMarkers();
        }
    }, [bookmarks, googleMapsApi]);

    useEffect(() => {
        currentMap?.setCenter(geolocation);
    }, [currentMap, isGeolocalizing]);

    useEffect(() => {
        if (currentListener) {
            google.maps.event.removeListener(currentListener);
        }
        currentListener = currentMap?.addListener('click', (event) => {
            if (event.placeId) {
                event.stop();
                clearInfoWindowAndMarker();
                displayPlace(event.placeId);
            }
        });
        dispatch(getAllBookmarksRequest());
    }, [currentMap]);

    return <div className="Map" />;
}

export default Map;

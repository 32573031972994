import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import Navbar from '../Navbar';
import Map from '../Map';
import './App.css';
import { dequeueNotification } from '../../reducers/notificationsSlice';

function App() {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const notificationQueue = useSelector((state) => state.notifications.queue);

    useEffect(() => {
        if (notificationQueue.length > 0) {
            const currentNotification = notificationQueue[0];

            const snackBarConfig = {
                variant: currentNotification.type,
                action: (key) => (
                    <IconButton
                        onClick={() => closeSnackbar(key)}
                        color="inherit"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                ),
            };

            enqueueSnackbar(currentNotification.message, snackBarConfig);
            dispatch(dequeueNotification());
        }
    }, [notificationQueue, enqueueSnackbar]);

    return (
        <div className="App">
            <Navbar />
            <Map />
            <Outlet />
        </div>
    );
}

export default App;

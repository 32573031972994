import {
    addBookmarkRequest,
    getAllBookmarksRequest,
    removeBookmarkRequest,
    removeBookmarksRequest,
} from '../reducers/bookmarksSlice';

const bookmarkMiddleware = (store) => (next) => (action) => {
    switch (action.type) {
        case addBookmarkRequest.fulfilled.toString():
        case removeBookmarkRequest.fulfilled.toString():
        case removeBookmarksRequest.fulfilled.toString(): {
            store.dispatch(getAllBookmarksRequest());
            return next(action);
        }
        default:
            return next(action);
    }
};

export default bookmarkMiddleware;

const getFormattedBookmark = (bookmark) => {
    return {
        name: bookmark.name,
        address: bookmark.formatted_address,
        lat: bookmark.geometry.location.lat(),
        lng: bookmark.geometry.location.lng(),
        place_id: bookmark.place_id,
    };
};

const postBookmark = async (bookmark) => {
    const response = await fetch('http://localhost:8080/api/bookmarks', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(getFormattedBookmark(bookmark)),
    });
    return response;
};

const getBookmarks = async () => {
    const response = await fetch('http://localhost:8080/api/bookmarks');
    const data = await response.json();
    return data;
};

const deleteBookmark = async (bookmarkId) => {
    const response = await fetch(
        `http://localhost:8080/api/bookmarks/${bookmarkId}`,
        {
            method: 'DELETE',
        }
    );
    return response;
};

const deleteBookmarks = async (bookmarkIds) => {
    const response = await fetch(`http://localhost:8080/api/bookmarks/bulk`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bookmarkIds),
    });
    return response;
};

export default { postBookmark, getBookmarks, deleteBookmark, deleteBookmarks };

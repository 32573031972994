import { useEffect, useState } from 'react';

const useGoogleMapsApi = () => {
    const [googleMapsApi, setGoogleMapsApi] = useState(null);

    useEffect(() => {
        const loadApi = async () => {
            try {
                const { InfoWindow, Map } = await google.maps.importLibrary(
                    'maps'
                );
                const { AdvancedMarkerElement, PinElement } =
                    await google.maps.importLibrary('marker');
                setGoogleMapsApi({
                    InfoWindow,
                    Map,
                    AdvancedMarkerElement,
                    PinElement,
                });
            } catch (error) {
                console.error('Error loading Google Maps API', error);
            }
        };
        loadApi();
    }, []);

    return googleMapsApi;
};

export default useGoogleMapsApi;

import React from 'react';

import App from './components/App';
import Bookmarks from './components/Bookmarks';
import Home from './components/Home';

const routesConfig = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '',
                element: <Home />,
            },
            {
                path: '/bookmarks',
                element: <Bookmarks />,
            },
        ],
    },
];

export default routesConfig;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

import GenericDialog from '../GenericDialog';
import { formatBookmarkDate } from './utils';
import {
    selectAllBookmarks,
    removeBookmarksRequest,
} from '../../reducers/bookmarksSlice';

const columnDefinition = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'address', headerName: 'Address', width: 350 },
    {
        field: 'createdAt',
        headerName: 'Created at',
        width: 150,
        valueGetter: formatBookmarkDate,
    },
    {
        field: 'updatedAt',
        headerName: 'Updated at',
        width: 150,
        valueGetter: formatBookmarkDate,
    },
];

function Bookmarks() {
    const dispatch = useDispatch();

    const [selectedIds, setSelectedIds] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const bookmarks = useSelector(selectAllBookmarks);

    return (
        <>
            <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <Button
                    disabled={selectedIds.length === 0}
                    size="xs"
                    color="primary"
                    variant="contained"
                    onClick={() => setIsDialogOpen(true)}
                >
                    Delete selected
                </Button>
                <GenericDialog
                    open={isDialogOpen}
                    title="Are you sure you want to remove these bookmarks?"
                    description="This action cannot be undone"
                    onAgree={() => {
                        setIsDialogOpen(false);
                        dispatch(removeBookmarksRequest(selectedIds));
                    }}
                    onClose={() => setIsDialogOpen(false)}
                />
            </Toolbar>
            <DataGrid
                rows={bookmarks}
                columns={columnDefinition}
                checkboxSelection
                disableRowSelectionOnClick
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                onRowSelectionModelChange={(ids) => {
                    setSelectedIds([...ids]);
                }}
                slots={{
                    toolbar: GridToolbar,
                }}
            />
        </>
    );
}

export default Bookmarks;
